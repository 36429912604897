import React, { FC } from 'react';
import clsx from 'clsx';
import useLayoutTheme from 'hooks/useLayoutTheme';

import styles from './FormContainer.module.scss';

interface FormContainerProps {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  className?: string;
  icon?: JSX.Element;
}

const FormContainer: FC<FormContainerProps> = ({ children, title, subtitle, icon, className }) => {
  const { themeStyles } = useLayoutTheme();
  return (
    <div className={clsx(styles.container, className, themeStyles.formContainer)}>
      {(icon || title || subtitle) && (
        <div className={styles.titleArea}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {title && <h1 className={styles.title}>{title}</h1>}
          {subtitle && <div className={clsx(styles.subtitle, themeStyles.subtitle)}>{subtitle}</div>}
        </div>
      )}
      {children}
    </div>
  );
};

export default FormContainer;
