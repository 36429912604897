import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootReducer from 'handlers';
import saveApplicationStepMiddleware from 'middlewares/saveApplicationStep';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';
import { CurrentFlow } from 'enums/CurrentFlow';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';
import { FLOW_ARG_TO_CURRENT_FLOW } from 'utils/flowArgToCurrentFlow';
import { UrlParams } from 'enums/UrlParams';

export const history = createBrowserHistory();

const urlSearchParams = new URLSearchParams(window.location.search);

const flowParam = urlSearchParams.get(UrlParams.Flow);

const defaultFlows = Object.values(CurrentFlow) as CurrentFlow[];

const possibleFlows = (flowParam !== null ? [FLOW_ARG_TO_CURRENT_FLOW[flowParam]] : defaultFlows) ?? defaultFlows;

const persistedState = getLocalStorageItem(LocalStorageKeyName.ApplicationState);

const initialState =
  urlSearchParams.get(UrlParams.ResumeProcess) || !possibleFlows.includes(persistedState?.applicationStep.currentFlow)
    ? undefined
    : persistedState;

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), saveApplicationStepMiddleware],
  preloadedState: initialState || undefined,
});

export type AppDispatch = typeof store.dispatch;

export default store;
