export enum PartnerName {
  Flasha = 'flasha',
  Fpma = 'fpma',
  Hillcrest = 'hillcrest',
  SunyDownstate = 'suny-downstate',
}

export const getPartnerName = (partner: PartnerName) => {
  const index = Object.values(PartnerName).indexOf(partner);
  if (index === -1) {
    return partner
      .split('-')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
      .join(' ');
  }
  const key = Object.keys(PartnerName)[index];
  return key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
};

export const getPartnerFromEmployerName = (employerName: string) => {
  const employerNameArr = employerName.split(' ').map((word) => word.toLocaleLowerCase());

  return Object.values(PartnerName).find((employer) => employerNameArr.includes(employer));
};
