export enum StudentLoanEligibilityResult {
  Eligible = 'eligible',
  NotEligible = 'notEligible',
  Error = 'error',
}

export enum StudentLoanEmployerResult {
  NonProfit = 'nonProfit',
  ForProfit = 'forProfit',
  NotSure = 'notSure',
}

export enum StudentLoanForgivenessAwarenessResult {
  Yes = 'yes',
  No = 'no',
}

export enum StudentLoanNotEnrolledResult {
  Applying = 'applying',
  WontApprove = 'wontApprove',
  DifficultToApply = 'difficultToApply',
  WontSaveMoney = 'wontSaveMoney',
}

export enum StudentLoanWhyNotEnrolledResult {
  WontApprove = 'wontApprove',
  DifficultToApply = 'difficultToApply',
  WontSaveMoney = 'wontSaveMoney',
}

export enum StudentLoanSavingsResult {
  Next = 'next',
  NotNow = 'notNow',
}

export enum StudentLoanBookAppointmentResult {
  Schedule = 'schedule',
  NotNow = 'notNow',
}

export enum StudentLoanSaveRepaymentPlanResult {
  Enrolled = 'enrolled',
  NotEnrolled = 'notEnrolled',
  HaveNotHeard = 'haveNotHeard',
}

export enum StudentLoanBookAdvisorResult {
  Schedule = 'schedule',
  NotNow = 'notNow',
}

export type StudentLoanForgivenessResults =
  | StudentLoanEligibilityResult
  | StudentLoanEmployerResult
  | StudentLoanForgivenessAwarenessResult
  | StudentLoanNotEnrolledResult
  | StudentLoanWhyNotEnrolledResult
  | StudentLoanSavingsResult
  | StudentLoanBookAppointmentResult
  | StudentLoanSaveRepaymentPlanResult
  | StudentLoanSavingsResult
  | StudentLoanBookAdvisorResult;
