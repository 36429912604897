import React from 'react';
import clsx from 'clsx';

import styles from './RadioButtonList.module.scss';

interface RadioButtonListProps {
  options: {
    id: string;
    label: string;
  }[];
  selected: string | null;
  setSelected: (id: any) => void;
}

const RadioButtonList = ({ options, selected, setSelected }: RadioButtonListProps) => (
  <div className={styles.container}>
    {options.map((option) => (
      <div key={option.id} className={styles.option} onClick={() => setSelected(option.id)}>
        <div
          className={clsx(styles.radio, {
            [styles.selected]: selected === option.id,
          })}
        />
        <p className={styles.label}>{option.label}</p>
      </div>
    ))}
  </div>
);

export default RadioButtonList;
